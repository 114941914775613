const clearAfter = (target) => {
  if (!target || !target?.dataset?.position) return

  // set position for auto scroll
  window.state.autoScrollPosition = state.positions.indexOf(parseFloat(target.dataset.position))

  const index = parseInt(target.dataset.num)
  const spokenClass = "spoken";
  window.state.wordMap?.slice(index + 1)
      .filter((obj) => obj.element.classList.contains(spokenClass))
      .forEach((wordMapEl) => {
        wordMapEl.element.classList.remove(spokenClass);
      });
}

export default clearAfter
