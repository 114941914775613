import lineHeight from "../utility/lineHeight"
import customScrollTo from "../utility/customScrollTo"
import offsetAmount from "../utility/offsetAmount"

const maxCallInterval = 500
let lastCalled;

const goTo = (destination, speedOverride = null) => {
  if (performance.now() - lastCalled < maxCallInterval) {
    // called too much - ignore
    return;
  }

  lastCalled = performance.now();

  window.state.interrupted && setState('interrupted', false)

  if (typeof destination != 'number') {
    destination = parseInt(destination)
  }

  let speed;
  if (window.state.scrollMethod == 'voice' && !speedOverride) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlSpeed = parseInt(urlParams.get('speed'))
    speed = urlSpeed || 350
    destination = destination - lineHeight()/2
  } else {
    speed = window.state.autoSpeed + 15
    // a little longer than the next call by 'go' so its smooth
  }
  customScrollTo(destination - offsetAmount() + lineHeight()/1.75, speedOverride || speed)
}

export default goTo
