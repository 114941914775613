import checkMicPermission from '../utility/checkMicPermission'

const tour = (state, direct = false) => {
  const script = document.querySelector('#script-body')

  if (!script) return

  window.addEventListener('new_stream_results', (e) => {
    const { results } = e.detail
    const word = results[0]

    const animate = (element, animation) => {
      document.querySelectorAll(element).forEach((text) => {
        text.classList.add('animate__animated')
        text.classList.add(`animate__${animation}`)
        text.style.color = '#e2256b'

        setTimeout(function() {
          text.style.color = ''
          text.classList.remove('animate__animated')
          text.classList.remove(`animate__${animation}`)
        }, 1000)
      })
    }

    const tip = (el) => {
      document.querySelector(el)?._tippy?.show()

      setTimeout(function() {
        document.querySelector(el)?._tippy.hide()
      }, 1000)
    }

    const triggers = {
      brackets: {
        action: () => animate('.bracketed', 'tada')
      },
      square: {
        action: () => animate('.bracketed', 'tada')
      },
      bottom: {
        action: () => animate('#settings', 'tada')
      },
      mirror: {
        action: () => {
          animate('.js-guide-mirror', 'tada')
          tip('.js-guide-mirror')
        }
      },
      remote: {
        action: () => {
          // animate('.js-guide-remote', 'tada')
          // tip('.js-guide-remote')
        }
      },
      overlay: {
        action: () => {
          animate('.js-guide-overlay', 'tada')
          tip('.js-guide-overlay')
        }
      },
    }

    if (Object.keys(triggers).includes(word)) {
      document.querySelector('.teleprompter-controls').classList.remove('slide-down')
      setTimeout(function() {
        triggers[word].action()
      }, 500)
    }
  })
}

export default tour
