import axios from "axios";
import { Notyf } from "notyf";
import parseScriptInput from "../utility/parseScriptInput";
import clearAfter from "./clearAfter";
import goTo from "./goTo";
import registerWordAsSpoken from "./registerWordAsSpoken";
import tippy from 'tippy.js';
import hasFeature from "../utility/hasFeature";
import closest from "../utility/closest";
import Display from "../utility/Display";

let notyf;
let pageBookmarks = []

function createElementFromHTML(htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim()
  return div.firstChild;
}

const addBookmarkIconsToParagraphs = () => {
  document.querySelectorAll('.teleprompter p').forEach((p, i) => {
    if (p.innerText.length < 3) return
    const tpl = document.querySelector('#bookmark').innerHTML
    const tplElement = createElementFromHTML(tpl)
    tplElement.dataset.id = i

    if (p.innerHTML.toLowerCase().includes('[bookmark]')) {
      tplElement.classList.add('active')
      pageBookmarks.push(tplElement)
    }

    p.insertBefore(tplElement, p.firstChild);
  })

  tippy('.js-bookmark-tooltip', {
    theme: 'light',
    placement: 'left',
  });
}

const saveScript = (script, cb) => {
  const parsed = parseScriptInput(script)

  axios.patch(document.querySelector('#settings').dataset.path, {
    script: {
      body: script,
      parsed_body: parsed,
    }
  })
  .then((res) => {
    cb()
    applyBookmarks()
  })
}

const bookmarkBtnHandler = () => {
  document.querySelectorAll('.js-bookmark-btn').forEach((bookmarkBtn) => {
    bookmarkBtn.addEventListener('click', (e) => {
      mixpanel?.track('teleprompter:bookmark')

      if (!hasFeature('bookmark_sections')) {
        Display.upgrade('bookmark_sections')
        return
      }

      const btn = e.currentTarget
      const element = btn.closest('p')
      let updated = false;

      setTimeout(function() {
        if (!updated) btn.classList.add('loading') // show loading if it takes a bit
      }, 500)

      axios.get(document.querySelector('#settings').dataset.path)
      .then((res) => {
        const script = res.data.body
        const holder = document.createElement('div')
        holder.innerHTML = script
        const paragraphs = Array.from(holder.querySelectorAll('p'))

        const paragraphToBookmark = paragraphs.filter(p => {

          return p.innerText.replace(/[^\w]/g, '').includes(element.innerText.replace(/[^\w]/g, ''))
        })


        if (Array.from(btn.classList).includes('active')) {
          const paragraphHTML = paragraphToBookmark[0].outerHTML
          let removedBookmark = paragraphHTML.replace('[Bookmark] ', '')
              removedBookmark = removedBookmark.replace('[bookmark] ', '') // just in case its lowercase

          let newScript = script.replace(paragraphHTML, removedBookmark)

          saveScript(newScript, () => {
            btn.classList.remove('active')
            applyBookmarks()
            notyf.success('Removed bookmark')
            window.Remote.action('fetch-script')
            let index = pageBookmarks.indexOf(btn)
            delete pageBookmarks[index]
            btn.classList.remove('loading')
            updated = true
          })

          return
        }

        if (paragraphToBookmark.length) {
          const paragraphHTML = paragraphToBookmark[0].outerHTML
          let addedBookmark = paragraphHTML.replace('<p>', '<p>[Bookmark] ')
          let newScript = script.replace(paragraphHTML, addedBookmark)

          saveScript(newScript, () => {
            btn.classList.add('active')
            pageBookmarks.push(btn)
            applyBookmarks()
            notyf.success('Added bookmark')
            window.Remote.action('fetch-script')
            btn.classList.remove('loading')
            updated = true
          })

        } else {
          notyf.error('Could not add bookmark. Please contact support.')
          return
        }
      })
    })
  })
}

const applyBookmarks = () => {
  document.querySelector('.bookmarks').innerHTML = ''
  // sort it
  pageBookmarks.sort((a, b) => {
    const idA = parseInt(a.dataset.id)
    const idB = parseInt(b.dataset.id)

    if (idA < idB) return -1;
    if (idA > idB) return 1;
    return 0;
  })

  pageBookmarks.forEach((bookmark, i) => {
    const previewText = bookmark.closest('p').innerText.slice(0, 30) + '...';
    const anchorButton = createElementFromHTML(`
      <button data-preview="${previewText}" data-bookmark="${ bookmark.dataset.id }"></button>
    `)

    anchorButton.addEventListener('click', function (e) {
      const isActive = window.store.prompter.active
      if (isActive) document.querySelector('#start').click()

      setTimeout(function () {
        const bookmarkPosition = () => Math.round(bookmark.getBoundingClientRect().top + window.pageYOffset)
        const targetPosition = closest(bookmarkPosition(), window.state.positions)

        const word = document.querySelector(`span[data-position="${targetPosition}"]`)
        registerWordAsSpoken(word, { to: 'bookmark' })
        goTo(bookmarkPosition(), 500)
        clearAfter(word)
      }, isActive ? 500 : 0)
    })

    document.querySelector('.bookmarks').appendChild(anchorButton)
  })
}

window.closest = closest

const bookmarking = () => {
  pageBookmarks = []
  document.querySelector('.bookmarks').innerHTML = ''

  notyf = new Notyf({
    duration: 3000,
    dismissible: true,
    position: {
      x: 'right',
      y: 'bottom',
    }
  });

  // if bookmarking enabled
  addBookmarkIconsToParagraphs()
  applyBookmarks()
  bookmarkBtnHandler()
}

export default bookmarking
