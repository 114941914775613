const grayOutPrevious = (word, nearThreshold = window.state.wordsPerLine * 3) => {
  if (!word || !word?.dataset?.num) {
    Logger(`grayOutPrevious: word or word dataset not found`)
    return
  }

  const num = parseInt(word.dataset.num)
  for (let i = 1; i < nearThreshold; i++) {
    const el = document.querySelector(`span[data-num="${num - i}"]:not(.spoken)`)
    if (el) {
      el.classList.add('spoken')
    }
  }
}

export default grayOutPrevious;
