import isElement from "../utility/isElement"

const registerWordAsSpoken = (element, options = {}) => {
  if (!element || !element?.dataset?.num) {
    Logger('registerWordAsSpoken: element not found', element)
    return
  }

  let to;

  if (isElement(element, 'span')) {
    element.classList.add('spoken')
  }

  if (options.to) {
    to = options.to;
  }

  window.Remote?.action(`scroll:${element.dataset.num}${ to ? `:${to}` : '' }`)
  window.state.lastRecognizedTime = new Date().getTime();
  window.state.lastWordNum = element.dataset.num

  document.querySelector('.start-point')?.classList?.remove('start-point')
  element.classList.add('start-point')

  if (!options.skipSetAutoScrollPosition) {
    window.state.autoScrollPosition =  window.state.positions.indexOf(parseInt(element.dataset.position))
  }

  return true
}

export default registerWordAsSpoken
