import parseScriptInput from "../utility/parseScriptInput";

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

const brackets = () => {
  let script = document.getElementById('script')
  let text = script.innerHTML
  let newText = script.innerHTML

  function stripHtml(input, allowed) {
    allowed = (((allowed || '') + '')
    .toLowerCase()
    .match(/<[a-z][a-z0-9]*>/g) || [])
    .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
    commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return input.replace(commentsAndPhpTags, '')
    .replace(tags, function($0, $1) {
      return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
    });
  }

  const findBracketedText = () => {
    let searching = false
    let indexes = { 0: [] }
    let key = 0

    Array.from(text).forEach((letter, i) => {
      // a bracket NOT proceeded by a tag
      if (letter == '[' && searching == false) {
        searching = true
        indexes[key].push(i)
      } else if (letter == '[') {
        searching = false
        key += 1 //
        indexes[key] = [i]
      }

      if (letter == ']') {
        searching = false
        indexes[key].push(i+1)
        key += 1
        indexes[key] = []
      }
    })

    return indexes
  }

  const indexes = findBracketedText();
  let parsed = []

  Object.keys(indexes).forEach((keys) => {
    let location = indexes[keys]
    if (location.length < 2) return

    let slice = Array.from(text)
                  .slice(location[0], location[1])
                  .join('')

    let html = stripHtml(slice, '<p>')

    let bracketedString;
    let style;

    if (html.toLowerCase() == '[bookmark]' || slice.toLowerCase() == '[bookmark]') {
      style = 'style="display:none"'
    }

    if (html) {
      bracketedString = `<span class="bracketed" ${style}>${html}</span>`
    } else {
      bracketedString = `<span class="bracketed" ${style}>${slice}</span>`
    }

    let parsedString = parseScriptInput(bracketedString, 'class="bracketed" data-bracketed');

    parsedString = parsedString.replace('<span class="bracketed" undefined>', '')
                                        .replace('</span></span>', '</span>')

    if (!parsed.includes(slice)) {
      newText = replaceAll(newText, slice, parsedString)
      parsed.push(slice)
    }
  })

  script.innerHTML = newText

  /*
    take nested brackets and remove their parents
    <span data-word="" data-position="1375"> <--- remove this
      <span class="bracketed" data-bracketed="" data-word="" data-position="1375">[Escherichia</span>
      <span class="bracketed" data-bracketed="" data-word="" data-position="1375">coli,</span>
      ....
  */

  script.querySelectorAll('span span.bracketed:first-child').forEach((node) => {
    let content = node?.parentElement?.innerHTML
    if (content && node && node.parentElement && node.parentElement.dataset.word == '') {
      node.parentElement.outerHTML = content
    }
  })
}

export default brackets
