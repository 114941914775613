window.interruptScroll = () => {
  setState('interrupted', true)
}

const customScrollTo = (yPos, duration = 600) => {
  if (window.state.interrupted) return;
  const startY = window.scrollY;
  const difference = yPos - startY;

  if (window.state.positions) { // prevent jerky scrolling
    const { lineHeight } = window.state
    if (Math.abs(difference) < lineHeight/2) return
  }

  const startTime = performance.now();
  const step = () => {
    if (window.state.interrupted) return;

    let progress = (performance.now() - startTime) / duration;

    const amount = easingFunc(progress);
    window.scrollTo({ top: startY + amount * difference });

    if (progress < 0.99) {
      window.requestAnimationFrame(step);
    }
  };

  step();
}

// Easing function from https://gist.github.com/gre/1650294
const easingFunc = t => t

export default customScrollTo;
