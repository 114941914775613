const wordsPerLine = () => {
  let words = Array.from(document.querySelectorAll('span[data-word]')).slice(0,40)
  let occurrences = words.map((word) => word.dataset.position)

  let count = {}

  occurrences.forEach((occurrence) => {
      if (!count[occurrence]) {
          count[occurrence] = 1
      } else {
          count[occurrence]++
      }
  })

  if (!Object.values(count).length) return

  const perLine = Math.round(Object.values(count).reduce((a, b) => a + b) / Object.values(count).length)
  const val = perLine < 3 ? 3 : perLine
  window.state.wordsPerLine = val
  return val
}

export default wordsPerLine
