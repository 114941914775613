function stopAndRemoveTrack(mediaStream) {
  return function(track) {
    track.stop();
    mediaStream.removeTrack(track);
  };
}

function stopMediaStream(mediaStream) {
  if (!mediaStream) {
    return;
  }

  mediaStream.getTracks().forEach(stopAndRemoveTrack(mediaStream));
}

const stop = (stopRemote = true) => {
  const { state } = window

  if (state.stream) {
    try {
      stopMediaStream(state.stream)
      window.stopRecognizeMicrophone()
    } catch (e) {
      Logger('error while stopping', e)
    }

    if (state.stream.stop !== undefined) {
      state.stream.stop()
    }

    if (state.stream.stopContinuousRecognitionAsync !== undefined) {
      state.stream.stopContinuousRecognitionAsync()
    }
  }

  state.stream = null

  // clear timer
  clearInterval(state.timerId)
  window.interruptScroll() // stop mid scroll 🛑

  state.timerId = null

  // reset auto scroll
  clearTimeout(state.autoIntervalTimer)
  state.autoIntervalTimer = null

  let startBtn = document.querySelector('#start')
  let listeningBar = document.querySelector('.js-listening-bar')

  startBtn && startBtn.classList.remove('listening')

  document.querySelector('.talking-indicator').style.display = 'none'

  if (listeningBar) {
    listeningBar.style.width = '100%'
    listeningBar.classList.remove('warning')
    listeningBar.innerHTML = ''
  }

  window.dispatchEvent(new CustomEvent('script_stop', {}))

  // bring up the controls
  const controls = document.querySelector('.teleprompter-controls')
  controls.classList.remove('slide-down')
}

window.stop = stop
export default stop
