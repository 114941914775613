import axios from "axios"
import brackets from "./brackets"
import watcher from "./watcher"
import wordClickHandler from "./wordClickHandler"
import bookmarking from "./bookmarking"
import setWordPositions from "./setWordPositions"
import detectInternet from "../utility/detectInternet"
import tour from "./tour"
import setScriptMarginTop from "../utility/setScriptMarginTop"

const fetchAndPrepareScript = (options = {}) => {
  const { initTeleprompter } = options;
  const scriptElement = document.querySelector('#script')

  if (!scriptElement) return;

  // fetch it
  const path = document.querySelector('#settings').dataset.path

  axios.get(path)
  .then((res) => {
    if (!res.data.parsed_body) return

    let parsedBody = res.data.parsed_body.replace(/></g, '> <') // fixed weird spacing bug for colored text

      scriptElement.innerHTML = parsedBody

      document.querySelector('.teleprompter').classList.remove('teleprompter--loading')
      document.querySelector('.teleprompter').classList.add('teleprompter--ready')
      detectInternet()
      tour(state)

      initTeleprompter && initTeleprompter()

      brackets()

      // set offset
      const topOffset = localStorage.getItem('topOffset')

      if (topOffset && document.querySelector('#top_offset')) {
          const offset = JSON.parse(topOffset)
          const id = document.querySelector('#script-body').dataset.scriptId

          if (offset.id == id) {
            setState('topOffset', parseInt(offset.value))
            document.querySelector('#top_offset').value = parseInt(offset.value)
            setScriptMarginTop()
          }
      }

      setWordPositions()

      Uatu.on('set_positions', () => {
        bookmarking()
        wordClickHandler(state)
        setScriptMarginTop()
        setWordPositions()
      }, { once: true })

    })
}

export default fetchAndPrepareScript
