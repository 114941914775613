import { determineService } from "../utility/serviceUtil"; // Import the utility function

const DELAY = 3000;
const TOKEN_EXPIRATION = 2 * 60 * 1000; // 2 minutes in milliseconds
const TOKEN_STORAGE_KEY = 'tokenData';

const fetchToken = (state, cb, forceFetch = false) => { // Added forceFetch parameter
  const currentTime = new Date().getTime();
  const scriptUpdatedAt = document.getElementById('script-body').getAttribute('data-updated-at');
  const storedTokenData = JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY) || '{}');
  const service = determineService(); // Use the utility function
  console.log('service', service);

  if (!forceFetch && storedTokenData.token &&
      (currentTime - storedTokenData.timestamp < TOKEN_EXPIRATION) &&
      (storedTokenData.updatedAt === scriptUpdatedAt) &&
      (storedTokenData.service === service)) {
    state.token = storedTokenData.token;
    window.Logger('reusing token', state.token);
    cb();
    return;
  }

  window.state.service = service;

  fetch(`${window.location.origin}/theme`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      authenticity_token: window._token,
      option: 'fetch',
      service: service,
    })
  })
  .then((data) => data.json())
  .then(function(_token) {
    state.token = _token.access_token;
    const tokenData = {
      token: _token.access_token,
      timestamp: currentTime,
      updatedAt: scriptUpdatedAt, // Store the script's updated_at attribute
      service: service // Store the service
    };
    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(tokenData));
    window.Logger('fetched token', state.token);
    setTimeout(cb, DELAY);
  }).catch(function(error) {
    window.swal('Something went wrong...', `
      An error occurred... please <a href="javascript:window.location.href=window.location.href">refresh this page</a>
      or contact support <a href="mailto:team@speakflow.com">team@speakflow.com</a>.
      This error will be resolved shortly.
    `, 'warning', 'Okay', null, null, null);
    window.Logger('something went wrong', error);
  });
}

export default fetchToken;
