import detectInternet from "../utility/detectInternet"
import fetchToken from "./fetchToken"
import setWordPositions from "./setWordPositions"
import stop from "./stop"
import wordClickHandler from "./wordClickHandler"

const restart = (e) => {
  e && e.preventDefault()

  setStore('prompter.active', false)

  document.querySelectorAll('.spoken').forEach((el) => el.classList.remove('spoken'))
  window.scrollTo({ top: 0, behavior: 'smooth' })
  document.querySelector('.teleprompter').classList.add('teleprompter--loading')
  start && start.classList.add('disabled')

  fetchToken(state, () => {
      const start = document.querySelector('#start')
      start && start.classList.remove('disabled')
      setWordPositions()
      wordClickHandler(state)
      document.querySelector('.teleprompter').classList.remove('teleprompter--loading')
      detectInternet()
  })

  window.state.lastWordNum = 0
  window.state.lastRecognizedTime = null
  window.state.autoScrollPosition = 0
}

export default restart
