import setWordPositions from "../features/setWordPositions";
import lineHeight from "./lineHeight"

const setScriptMarginTop = () => {
  document.querySelector('#script').style.marginTop = `${window.state.topOffset * lineHeight()}px`
  document.querySelector('.js-readline').style.top = parseFloat(document.querySelector('#script').style.marginTop) + lineHeight() + 'px'
  // document.querySelector('.js-readline').style.height = lineHeight() + 'px'

  setWordPositions(true);
}

export default setScriptMarginTop
